<template>
    <div>
        <nav-primary></nav-primary>
        <div class="home">
        </div>
    </div>
</template>

<script>
import NavPrimary from '@/components/Nav/NavPrimary.vue'

export default {
    name: 'Home',
    components: {
        NavPrimary,
    },
}
</script>
